import {Link} from 'gatsby';
import React, {useContext, useState, useEffect} from 'react';
import {ContextBar, ContextItem} from 'src/components/layout/contextBar';
import Layout, {ContentWrapper} from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import {Card} from 'src/components/ui/card';
import styles from './index.module.scss';

export default () => {
  return (
    <Layout>
      <SEO title='Help' />
      <ContextBar>
        <ContextItem
          text="Help"
        />
      </ContextBar>
      <ContentWrapper>
        <Card className={styles.section}>
          <div className={styles.helpTitle}>Tags</div>
          You can use #tags to organize your entries.
          Some tags are reserved and have special behavior.
          Just add the tag anywhere in your entry.
          <div>Reserved tags:</div>
          <table className={styles.tagTable}>
            <tbody>
              <tr>
                <td>#todo</td>
                <td>
                  Converts the entry into a to-do item, which you can
                  view on the To Do page and mark as complete.
                </td>
              </tr>
            </tbody>
          </table>
        </Card>
        <Card className={styles.section}>
          <div className={styles.helpTitle}>Support</div>
          <div>
            Need more help? Visit the <Link to="/account/support">support page</Link>.
          </div>
        </Card>
      </ContentWrapper>
    </Layout>
  );
};
